'use client';

import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useAuth } from '@/contexts/auth-context';
import { SiX } from '@icons-pack/react-simple-icons';
import { UserIcon } from 'lucide-react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

type LoginButtonProps = {
    text?: string;
    textSize?: string;
    withAvatar?: boolean;
    size?: 'lg' | 'default' | 'sm' | 'icon' | null;
};

const LoginButton: React.FC<LoginButtonProps> = ({ text, withAvatar = false, size = 'default' }) => {
    const router = useRouter();
    const { user, signUp } = useAuth();
    const trans = useTranslations('Buttons');

    return (
        <>
            {user ? (
                withAvatar ? (
                    // <UserButton
                    //     user={user}
                    //     withActions={false}
                    //     onSelect={() => router.push('/profile')}
                    // />
                    <Button>
                        <UserIcon className="size-4" />
                    </Button>
                ) : (
                    <Button asChild variant="default" className="shadow-sm hover:text-foreground hover:shadow-none">
                        <Link href="/profile">{trans('my_profile')}</Link>
                    </Button>
                )
            ) : (
                <Button size={size} onClick={() => signUp()}>
                    <SiX className="mr-1 size-[1.2rem] shrink-0" />
                    <Separator orientation="vertical" className="mx-2 h-full" />
                    {text ?? trans('login')}
                </Button>
            )}
        </>
    );
};

export default LoginButton;
