import type { IUser } from '@/types/api';
import { createContext, useContext } from 'react';

type AuthContextType = {
    user: IUser | null;
    login: (user: IUser) => void;
    logout: () => void;
    signUp: () => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
